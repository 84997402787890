@font-face {
  font-family: 'icomoon';
  src:  url('fonts/icomoon.eot?j5l62q');
  src:  url('fonts/icomoon.eot?j5l62q#iefix') format('embedded-opentype'),
    url('fonts/icomoon.ttf?j5l62q') format('truetype'),
    url('fonts/icomoon.woff?j5l62q') format('woff'),
    url('fonts/icomoon.svg?j5l62q#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-map:before {
  content: "\e94b";
}
.icon-map2:before {
  content: "\e94c";
}
.icon-download:before {
  content: "\e960";
}
.icon-cog:before {
  content: "\e994";
}
.icon-circle-up:before {
  content: "\ea41";
}
.icon-circle-down:before {
  content: "\ea43";
}
